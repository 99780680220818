import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

const StyledDialog = styled(Dialog)({
  '& .MuiDialog-paper': {
    maxWidth: '45rem',
  },
});

const StyledDialogActions = styled(DialogActions)({
  justifyContent: 'center',
  flexDirection: 'column',
});

const StyledDialogContent = styled(DialogContent)({
  paddingTop: '2rem',
  paddingBottom: '0rem',
});

const StyledDialogContentText = styled(DialogContentText)({
  textAlign: 'center',
  paddingLeft: '0.5rem',
  paddingRight: '0.5rem',
});

export { StyledDialog, StyledDialogActions, StyledDialogContent, StyledDialogContentText };
