import { apiFetch } from '@nrdy-marketing-engine/api-fetch';

export async function getIntlLocale() {
  try {
    const response = await apiFetch(process.env.NEXT_PUBLIC_VT_WWW_ENDPOINT + '/router_loc');
    return response.country_code;
  } catch (e) {
    console.error('Error fetching country code', e);
    return 'US';
  }
}
