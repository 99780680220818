function firstMappedTruthyValue<T>(arr: T[], mapFn: (value: T) => T) {
  for (let i = 0; i < arr.length; i++) {
    const mappedValue = mapFn(arr[i]);
    if (mappedValue) return mappedValue;
  }
}

const PERCENT_REGEX = /%(\w[^%\n]*)%/gi;
const JS_CODE_REGEX = /\${([^{}]+|(?:[^{}]+\${[^{}]+})+[^{}]+)}/gi;

const toNonNullString = (input: any): string =>
  input === null || input === undefined ? '' : input.toString();

/** Filters an array of strings by those that can be used as JS variables. */
const filterVars = (strings: string[]) => strings.filter((str) => str.match(/^[a-z]\w+$/));

/**
 * `{"foo": ..., "bar": ..., "baz": ...}` becomes `"{foo,bar,baz}"`
 * which can be used to destructure it into local variables for processing
 */
const generateDestructurableVars = (vars: Record<string, any>) =>
  '{' + filterVars(Object.keys(vars)).join(',') + '}';

/**
 * Interpolates a string by processing any `%variable%` or `${javascript}` sections
 * as real evaluated, scoped JavaScript with limited access to any vars we
 * specify.
 *
 *     var interpolation = new Interpolation({ foo: 'bar' });
 *     const output = interpolation.parse("The value of foo is ${foo}.");
 */
export default class Interpolation {
  vars: Record<string, any>;
  varKeys: string;

  constructor(vars?: Record<string, any>) {
    this.vars = vars || {};
    this.varKeys = vars ? generateDestructurableVars(this.vars) : '{}';
  }

  /**
   * Finds all `%variable%` or `${javascript}` sections and interpolates them.
   *
   * We support both styles since NSP used to use `%variable%` syntax, but the
   * more modern `${javascript}` syntax presents greater flexibility.
   */
  parse(content: string) {
    return toNonNullString(content)
      .replace(PERCENT_REGEX, (_: any, code: string) => this.processPercentInterpolation(code))
      .replace(JS_CODE_REGEX, (_: any, code: string) => this.processCode(code));
  }

  /** Splits code by pipes and returns the first truthy value. */
  processPercentInterpolation(code: string) {
    return (
      firstMappedTruthyValue(code.split('|'), this.processSimple.bind(this)) || ''
    ).toString();
  }

  /**
   * Performs a simple interpolation without evals or runtime functions.
   * In other words, just a simple object property access in vars using dot notation.
   */
  processSimple(code: string) {
    if (code.startsWith('"') && code.endsWith('"')) return code.slice(1, -1);
    return code.split('.').reduce((acc, key) => acc && acc[key], this.vars) || '';
  }

  /**
   * Constructs a private function based on the given code and processes it with
   * the `vars` scoped to the function. Any errors are caught and silently discarded.
   *
   * Note that variables can either be accessed directly by their field name as local variables,
   * or via `vars` (which will return null values for missing fields rather than throwing errors).
   */
  processCode(code: string) {
    const func = new Function('vars', this.varKeys, `try { return ${code}; } catch { return ''; }`);
    return toNonNullString(func(this.vars, this.vars));
  }
}
