'use client';

import { Interpolation } from '@nrdy-marketing-engine/interpolation';
import IntlLocaleModal from '@nrdy-marketing-engine/organic/app/components/intl-locale-modal';
import {
  NSPLocales,
  localePrefixes,
  parseNSPLocales,
} from '@nrdy-marketing-engine/organic/app/helpers/i18n';
import { extractPathProps } from '@nrdy-marketing-engine/routing/src/extract-path-props';
import { usePathname } from 'next/navigation';
import { useMemo } from 'react';
import * as footeri18nEnCa from '../i18n/footer.en-ca.yml';
import * as footerni18nEnGb from '../i18n/footer.en-gb.yml';
import * as footeri18n from '../i18n/footer.en.yml';
import DesktopNavItem from './desktop-nav-item';
import SimpleFooter from './simple-footer';

const {
  en: { seo_footer: i18n },
} = footeri18n;

const {
  en_ca: { seo_footer: i18nEnCa },
} = footeri18nEnCa;

const {
  en_gb: { seo_footer: i18nEnGb },
} = footerni18nEnGb;

type NavItems = {
  [key: string]: {
    href: string;
    text: string;
  };
};

interface NavProps {
  [key: string]: {
    items: NavItems;
    text: string;
  };
}

interface SubnavProps {
  intlPrefix: string;
  subnav: NavItems;
}

const navItemsProcessor = (nav: NavProps, intlPrefix: string) => {
  const navEntries = Object.entries(nav);

  return navEntries.map(([itemName, item]) => ({
    ...item,
    content: <SubNav intlPrefix={intlPrefix} subnav={item.items} />,
    name: itemName,
    title: item.text,
  }));
};

function DesktopNavItemSet({ items }) {
  return (
    <ul className="md:grid grid-flow-row grid-cols-2 lg:grid-cols-4 md:text-base gap-6 py-4">
      {items.map((item) => (
        <DesktopNavItem key={item.name} {...item} />
      ))}
    </ul>
  );
}

function Navigation({ nav }: { nav: NavProps }) {
  const { intlPrefix } = extractPathProps(usePathname());
  const processedNavItems = useMemo(() => navItemsProcessor(nav, intlPrefix), [nav, intlPrefix]);

  return (
    <div className="bg-light-alt text-dark mt-auto dark:bg-[#2c4872] dark:text-white">
      <div className="container-xl py-2">
        <DesktopNavItemSet items={processedNavItems} />
      </div>
    </div>
  );
}

function SubNav({ subnav, intlPrefix }: SubnavProps) {
  const interpolation = useMemo(() => new Interpolation({ prefix: intlPrefix }), [intlPrefix]);

  return (
    <>
      {Object.entries(subnav).map(([key, item]) => (
        <li key={key}>
          <a
            className="lg:text-sm hover:underline -mx-2 py-1 px-2 block"
            href={interpolation.parse(item.href)}
          >
            {item.text}
          </a>
        </li>
      ))}
    </>
  );
}

function SeoFooter({
  locales = ['en'],
  userLoggedIn = false,
}: {
  locales?: NSPLocales[];
  userLoggedIn?: boolean;
}) {
  const pathname = usePathname()?.replace('/nme', '');
  const intlPrefix = extractPathProps(pathname)?.intlPrefix as localePrefixes;

  const intlFooter = parseNSPLocales(locales).length > 1;
  const parsedLocales = parseNSPLocales(locales);

  let navigationData;

  switch (intlPrefix) {
    case '/ca':
      navigationData = i18nEnCa.navigation;
      break;
    case '/gb':
      navigationData = i18nEnGb.navigation;
      break;
    default:
      navigationData = i18n.navigation;
  }

  return (
    <footer>
      {!userLoggedIn && <Navigation nav={navigationData} />}
      {intlFooter === true && <IntlLocaleModal />}

      <SimpleFooter
        currentLocale={intlPrefix}
        intlFooter={intlFooter}
        localeList={parsedLocales}
        tag="div"
      />
    </footer>
  );
}

export default SeoFooter;
