import { useMemo } from 'react';
import Interpolation from './interpolation';
import useInterpolationVars from './use-interpolation-vars';

/**
 * Parses the given string and replaces all found interpolation tags with their respective
 * values based on the interpolation vars in the context of the current component.
 */
export default function useInterpolation(content: string) {
  const vars = useInterpolationVars();

  // Interpolation can take some cycles, so memoize it
  return useMemo(() => {
    const interpolation = new Interpolation(vars);
    return interpolation.parse(content);
  }, [content, vars]);
}
