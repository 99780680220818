'use client';

import clsx from 'clsx';
import { ComponentType, useState } from 'react';
import Minus from './minus.svg?svgr';
import Plus from './plus.svg?svgr';

interface DesktopNavItemProps {
  content: JSX.Element;
  name: string;
  title: string;
}

const wrapperClasses = (itemName: string) => {
  switch (itemName) {
    case 'about':
    case 'company':
      return '';
    case 'resources':
      return 'col-span-2 md:border-gray-300 md:pt-6 md:border-t-[1px] lg:border-t-0 lg:pt-0';
    default:
      return 'md:border-gray-300 md:border-t-[1px] col-span-2 lg:col-span-4 md:pt-6';
  }
};

const columnClasses = (itemName: string) => {
  switch (itemName) {
    case 'about':
    case 'company':
      return '';
    case 'resources':
      return 'columns-2 gap-2 lg:gap-6';
    default:
      return 'columns-2 lg:columns-4 gap-2 lg:gap-6';
  }
};

export default function DesktopNavItem({ name, content, title }: DesktopNavItemProps) {
  const [isOpen, setIsOpen] = useState(false);
  const Icon: ComponentType<{ className?: string }> = isOpen ? Minus : Plus;

  return (
    <li
      className={clsx(
        'border-b border-b-gray-300 last:border-b-0 md:border-0 dark:border-opacity-25',
        wrapperClasses(name)
      )}
    >
      <button
        className="font-bold leading-loose flex w-full text-left items-center py-2 md:cursor-default md:py-0"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="flex-1">{title}</span>
        <Icon className="md:hidden" />
      </button>
      <ul
        className={clsx(
          'grid-rows-4 md:grid-rows-2 pb-3 md:pb-0',
          !isOpen && 'hidden md:block',
          columnClasses(name)
        )}
      >
        {content}
      </ul>
    </li>
  );
}
