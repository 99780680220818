import { PanelContext } from '@nrdy-marketing-engine/form-context';
import { useNspContext } from '@nrdy-marketing-engine/nsp/src/nsp-context';
import { useContext, useMemo } from 'react';
import getPhrases, { GetPhrasesProps } from './get-phrases';

/**
 * Returns an object containing all available interpolation variables, based on the context
 * of the current component.
 */
export default function useInterpolationVars(): Record<string, any> {
  const { formData } = useContext(PanelContext);
  const nspContext = useNspContext();

  // Since we construct an object each time, memoize it so that it only changes
  // if our inputs ever change
  return useMemo(
    () => ({
      ...formData,
      form: nspContext.leadForm,
      locale: nspContext.locale,
      page: nspContext.page,
      phrases: getPhrases(formData as GetPhrasesProps),
      subject: nspContext.subject,
    }),
    [formData, nspContext.leadForm, nspContext.page, nspContext.subject, nspContext.locale]
  );
}
