interface PhraseMap {
  [key: string]: string;
}

interface ConditionalPhrase {
  condition: string;
  phrases: PhraseMap;
}

interface AllPhrases {
  conditionalPhrases: ConditionalPhrase[];
  phrases: PhraseMap;
}

export const allPhrases: AllPhrases = {
  conditionalPhrases: [
    {
      condition: 'Who_needs_help=child',
      phrases: {
        are_you: 'Is your child',
        are_you_lc: 'is your child',
        could_you: 'Could your child',
        could_you_lc: 'could your child',
        did_you: 'Did your child',
        did_you_lc: 'did your child',
        do_you: 'Does your child',
        do_you_lc: 'does your child',
        have_you: 'Has your child',
        have_you_lc: 'has your child',
        i: 'They',
        i_lc: 'they',
        i_am: 'They are',
        i_am_lc: 'they are',
        im: "They're",
        im_lc: "they're",
        will_you: 'Will your child',
        will_you_lc: 'will your child',
        would_you: 'Would your child',
        would_you_lc: 'would your child',
        you: 'Your child',
        you_lc: 'your child',
        you_are: 'Your child is',
        you_are_lc: 'your child is',
        your: "Your child's",
        your_lc: "your child's",
      },
    },
    {
      condition: 'Who_needs_help=other|employee|someone|friend',
      phrases: {
        are_you: 'Are they',
        are_you_lc: 'are they',
        could_you: 'Could they',
        could_you_lc: 'could they',
        did_you: 'Did they',
        did_you_lc: 'did they',
        do_you: 'Do they',
        do_you_lc: 'do they',
        have_you: 'Have they',
        have_you_lc: 'have they',
        i: 'They',
        i_lc: 'they',
        i_am: 'They are',
        i_am_lc: 'they are',
        im: "They're",
        im_lc: "they're",
        will_you: 'Will they',
        will_you_lc: 'will they',
        would_you: 'Would they',
        would_you_lc: 'would they',
        you: 'Them',
        you_lc: 'them',
        you_are: 'They are',
        you_are_lc: 'they are',
        your: 'Their',
        your_lc: 'their',
      },
    },
  ],
  phrases: {
    are_you: 'Are you',
    are_you_lc: 'are you',
    could_you: 'Could you',
    could_you_lc: 'could you',
    did_you: 'Did you',
    did_you_lc: 'did you',
    do_you: 'Do you',
    do_you_lc: 'do you',
    have_you: 'Have you',
    have_you_lc: 'have you',
    i: 'I',
    i_lc: 'I',
    i_am: 'I am',
    i_am_lc: 'I am',
    im: "I'm",
    im_lc: "I'm",
    will_you: 'Will you',
    will_you_lc: 'will you',
    would_you: 'Would you',
    would_you_lc: 'would you',
    you: 'You',
    you_lc: 'you',
    you_are: 'You are',
    you_are_lc: 'you are',
    your: 'Your',
    your_lc: 'your',
  },
};

export interface GetPhrasesProps {
  who_needs_help: string;
}

export default function getPhrases(getPhrasesProps?: GetPhrasesProps) {
  if (!getPhrasesProps?.who_needs_help) return allPhrases.phrases;

  const lowerCaseCondition = getPhrasesProps.who_needs_help.toLowerCase();

  return (
    allPhrases.conditionalPhrases.find(({ condition }) => {
      const regex = new RegExp(condition.split('=').at(1));

      return regex.test(lowerCaseCondition);
    })?.phrases ?? allPhrases.phrases
  );
}
