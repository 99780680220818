'use client';

import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import { useEffect, useState } from 'react';
import { LocaleHelper } from './helpers/client-helpers';
import { getIntlLocale } from './helpers/server-helpers';

import {
  StyledDialog,
  StyledDialogActions,
  StyledDialogContent,
  StyledDialogContentText,
} from './styled';

function IntlLocaleModal() {
  const [open, setOpen] = useState(false);
  const [country, setCountry] = useState('');
  const [buttonString, setButtonString] = useState('');
  const [redirectUrl, setRedirectUrl] = useState('');
  const localeHelper = new LocaleHelper();

  useEffect(() => {
    getIntlLocale().then((countryCode) => {
      localeHelper.setupValues(countryCode);
      setCountry(localeHelper.getCountry());
      setButtonString(localeHelper.buttonText);
      setRedirectUrl(localeHelper.redirectUrl);
      setOpen(localeHelper.showModal());
    });
  }, []);

  const handleClose = () => {
    localeHelper.setPreferredLocale(localeHelper.getCanonical());
    setOpen(false);
  };

  const handleRedirectClick = () => {
    let hostName = window.location.protocol + '//' + window.location.host;
    let envHostname = process.env.NEXT_PUBLIC_VT_WWW_ENDPOINT;
    let cleanedRedirectUrl = redirectUrl.replace(envHostname, hostName);
    localeHelper.setPreferredLocale(redirectUrl); // sets preferred locale with env to stay consistent

    window.location.replace(cleanedRedirectUrl); //redirects to constructed since localhost would otherwise go to staging
  };

  return (
    <>
      <StyledDialog onClose={() => {}} open={open}>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: '#ccc',
          }}
        >
          <CloseIcon />
        </IconButton>
        <StyledDialogContent dividers={false}>
          <StyledDialogContentText>
            It looks like you might be in {country}. Would you like to view locally relevant
            content?
          </StyledDialogContentText>
        </StyledDialogContent>
        <StyledDialogActions sx={{ border: 'none' }}>
          <Button color="primary" onClick={() => handleRedirectClick()}>
            {buttonString}
          </Button>
          <Link color="primary" component="button" onClick={handleClose}>
            No, stay here
          </Link>
        </StyledDialogActions>
      </StyledDialog>
    </>
  );
}

export default IntlLocaleModal;
